/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Typography } from '../typography';

export const Radio = ({ input, meta, children, disabled, ...props }) => {
	return (
		<Typography
			as="label"
			size="l"
			css={css`
				display: flex;
				align-items: center;
				position: relative;
				opacity: ${disabled ? 0.75 : 1};

				&:focus-within {
					& > div {
						outline: 1px dotted var(--color-fg);
					}
				}
			`}
			{...props}
		>
			<input
				type="radio"
				css={css`
					border: 0px none;
					clip: rect(0px, 0px, 0px, 0px);
					height: 1px;
					width: 1px;
					margin: -1px;
					padding: 0px;
					overflow: hidden;
					white-space: nowrap;
					position: absolute;
				`}
				disabled={disabled}
				{...input}
			/>
			<div
				css={(theme) => css`
					position: relative;
					width: ${theme.pxToUnit(32)};
					height: ${theme.pxToUnit(32)};
					border: 1px solid var(--color-fg);
					border-radius: 9999px;
					margin-right: 0.6em;
					flex: 0 0 auto;

					&::after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: ${theme.pxToUnit(21)};
						height: ${theme.pxToUnit(21)};
						background: ${input.checked ? 'var(--color-fg)' : 'transparent'};
						transition: background 100ms ease-out;
						border-radius: 9999px;
					}
				`}
			/>
			{children && (
				<span
					css={css`
						margin: 0 0.25em;
					`}
				>
					{children}
				</span>
			)}
		</Typography>
	);
};

export const RadioGroup = ({ direction = 'row', children, ...props }) => {
	return (
		<div
			css={(theme) => css`
                display: flex;
								flex-wrap:wrap;
                flex-direction: ${direction};
								margin: ${theme.pxToUnit(10)} 0;

                & > label {
                    margin-${direction === 'row' ? 'right' : 'bottom'}: 0.5em;
                }
            `}
			{...props}
		>
			{children}
		</div>
	);
};
